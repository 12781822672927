<template>
  <b-nav-item
    href="https://api.whatsapp.com/send/?phone=51946103263&text&type=phone_number&app_absent=0"
    target="_blank"
  >
    <feather-icon
      size="21"
      icon="MessageCircleIcon"
    />
  </b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
}
</script>
