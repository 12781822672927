export default [
  {
    header: 'Menu principal',
    resource: 'tiendaVer',
    action: 'tiendaVer',
  },
  {
    title: 'Gestión Empresa',
    route: 'empresaVer',
    icon: 'HomeIcon',
    resource: 'empresaVer',
    action: 'empresaVer',
  },
  {
    title: 'Cargas',
    route: 'cargasVer',
    icon: 'UploadCloudIcon',
    resource: 'cargasVer',
    action: 'cargasVer',
  },
  {
    title: 'Documentacion',
    route: 'documentacionVer',
    icon: 'UploadCloudIcon',
    resource: 'documentacionVer',
    action: 'documentacionVer',
  },
  {
    title: 'Roles',
    route: 'rolVer',
    icon: 'HelpCircleIcon',
    resource: 'rolVer',
    action: 'rolVer',
  },
  {
    title: 'Permisos',
    route: 'permisoVer',
    icon: 'HelpCircleIcon',
    resource: 'permisoVer',
    action: 'permisoVer',
  },
  {
    title: 'Notificaciones',
    route: 'notificacionVer',
    icon: 'CalendarIcon',
    resource: 'notificacionVer',
    action: 'notificacionVer',
  },
  {
    title: 'Tienda',
    route: 'tiendaVer',
    icon: 'UploadCloudIcon',
    resource: 'tiendaVer',
    action: 'tiendaVer',
  },
  {
    title: 'Trivia',
    route: 'triviaVer',
    icon: 'UploadCloudIcon',
    resource: 'triviaVer',
    action: 'triviaVer',
  },
  {
    title: 'Canje cupon',
    route: 'cuponVer',
    icon: 'InboxIcon',
    resource: 'cuponVer',
    action: 'cuponVer',
  },
  {
    title: 'Dashboard',
    route: 'dashboardVer',
    icon: 'PieChartIcon',
    resource: 'dashboardVer',
    action: 'dashboardVer',
  },
  {
    title: 'Reportes',
    route: 'reporteVer',
    icon: 'FileTextIcon',
    resource: 'reporteVer',
    action: 'reporteVer',
  },
]
